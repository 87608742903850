<template>
  <div>
  <b-card class="chat-widget" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <h5 class="mb-0">
          دردشة
        </h5>
      </div>
    </b-card-header>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
        <chat-log :chat-data="chatData" :user-data="userData" />
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form v-if="$can('read', 'evaluationRequest')" class="chat-app-form position-relative"
        @submit.prevent="sendMessage">
        <b-input-group class="input-group-merge position-relative form-send-message mr-1">
        <Mentionable  :keys="['@']" :items="items" offset="6" insert-space>
            <b-form-input v-model="chatInputMessage" placeholder="اكتب رسالتك" />
          </Mentionable>
          <b-button style="border-radius: 4px 0 0 4px;" variant="primary" type="submit">
            إرسال
          </b-button>
        </b-input-group>
      </b-form>
    </section>
  </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ChatLog from '@/views/modules/chat/Log.vue';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import evaluationRequestStore from '../evaluationRequestStore';
import  Mentionable from '@/components/Mentionable.vue';
// import '@/assets/css/floating-vue.css';

export default {
  mounted () {
    // Dynamically inject the CDN stylesheet into the <head> of the document
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/css/floating-vue.css'; // Replace with your CDN URL
    //document.head.appendChild(link);
  },
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BFormTextarea,
    // SFC
    ChatLog,

    // 3rd party
    VuePerfectScrollbar,
    Mentionable,
  },
  props: {
    evaluationRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
  {
    value: 'anis',
    label: 'أنيس',
  },
  {
    value: 'posva',
    label: 'Eduardo',
  },
  {
    value: 'atinux',
    label: 'Sébastien',
  },
],
      userData: JSON.parse(localStorage.getItem('userData')) || {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: '',
    };
  },
  mounted() {
    this.psToBottom();
  },
  methods: {
    sendMessage() {
      // save message to db
      this.$store
        .dispatch('evaluationRequest/saveMessage', {
          discussionData: {
            message: this.chatInputMessage,
            request_id: this.evaluationRequest.id,
            user_id: this.userData.id || null,
          },
          id: this.evaluationRequest.id,
        })
        .then(() => {
          this.chatData = [
            ...this.chatData,
            {
              message: this.chatInputMessage,
              user_id: this.userData.id,
              user: this.userData,
            },
          ];
          this.chatInputMessage = '';

          // Update scroll position
          // Scroll to bottom
          this.$nextTick(() => {
            this.psToBottom();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
  },
  setup(props) {
    const EVLUATION_REQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVLUATION_REQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVLUATION_REQUEST_STORE_MODULE_NAME);
    });

    const { discussion } = props.evaluationRequest;

    const chatData = discussion ? discussion.messages : [];
    const users = ref(null);
    store
      .dispatch('evaluationRequest/fetchUsers')
      .then((response) => {
        const { data } = response.data;
        users.value = data.map((c) => ({
          label: c.name,
          value: c.email.split('@')[0], // Extract the part before '@' from the email
          ...c,
        }));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          users.value = undefined;
        }
      })
    return {
      users,
      discussion,
      chatData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>